import React, { useEffect, useState, useRef } from 'react';
import Breadcrumb from '../components/Breadcrumb';
import Navbar from '../components/Navbar';
import './Common.css'
import Footer from '../components/Footer';
import { BsYoutube } from 'react-icons/bs';
import { Helmet } from 'react-helmet';
import emailjs from '@emailjs/browser';

const Groovecuter = () => {

  const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
  const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
  const userId = process.env.REACT_APP_EMAILJS_USER_ID;


  const [searchInput, setSearchInput] = useState('');

  const productsBreadcrumb = [
    { label: 'Home', url: '/' },
    { label: 'Products', url: '/products' },
    { label: 'Concrete Cutting Machine', url: '/groovecutter' },
  ];

  const openPDF = () => {
    window.open('/pdfs/Product Brochure.pdf', '_blank');
  };


  const [compactors, setCompactors] = useState([]);
  const [showQuoteForm, setShowQuoteForm] = useState(false);
  const [quoteFormData, setQuoteFormData] = useState({
    name: '',
    contact: '',
    email: '',
    query: '',
  });
  const [selectedProduct, setSelectedProduct] = useState(null);

  const quoteFormRef = useRef(); // Ref for the quote form

  useEffect(() => {
    // Fetch data from the local JSON file
    fetch('/data.json')
      .then((response) => response.json())
      .then((data) => {
        // Assuming "SoilCompaction" is the main array in your JSON file
        const compactorsData = data['ConcreteCutting'];

        // Filter out the products with category "Compactors"
        const compactorsProducts = compactorsData.filter(
          (product) => product.category === 'Groove Cutter'
        );

        setCompactors(compactorsProducts);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  //QuoteForm

  const handleQuoteFormChange = (field, value) => {
    setQuoteFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  // Function to send a quote request email using EmailJS
  const sendQuoteEmail = async () => {
    if (selectedProduct) {
      try {
        const response = await emailjs.send(
          serviceId,
          templateId,
          {
            product_name: selectedProduct.name, // Pass the product name to the template
            // Include other template variables here
            name: quoteFormData.name,
            contact: quoteFormData.contact,
            email: quoteFormData.email,
            query: quoteFormData.query,
          },
          userId
        );

        if (response.status === 200) {
          // Success, close the form and reset form data
          alert('Quote request submitted successfully!');
          setShowQuoteForm(false);
          setQuoteFormData({
            name: '',
            contact: '',
            email: '',
            query: '',
          });
        } else {
          console.error('Error submitting quote');
        }
      } catch (error) {
        console.error('Error submitting quote:', error);
        alert('Failed to send quote request. Please try again.');
      }
    }
  };

  const handleSubmitQuote = async (e) => {
    e.preventDefault();
    sendQuoteEmail();
  };

  const filteredCompactors = compactors.filter(product =>
    product.name.toLowerCase().includes(searchInput.toLowerCase())
  );


  return (
    <div>
      <Helmet>
        <title>Concrete Cutting Machine | Groove Cutter Machine</title>
        <meta name="description" content="Concrete Cutting Machine is a specialized construction tool designed for cutting precise grooves or channels into various materials." />
        <link rel="canonical" href="http://ablevibrators.com/#/groovecutter"></link>
      </Helmet>
      <Navbar />
      <div>
        <Breadcrumb items={productsBreadcrumb} />
      </div>
      <div className="compactors-page">

        <div className="all-about-products">
          <h1 className="page-title">Concrete Cutting Machine</h1>
          <p className="product-desc">Concrete Cutting Machine, which includes specialized variants like a concrete groove cutting machine or road cutting machine, is a powerful and indispensable tool in the construction and roadwork industries. These machines are engineered to efficiently and accurately cut through concrete and other sturdy materials. They are pivotal in various applications, from creating expansion joints and trenches to removing sections of concrete during construction, demolition, and renovation projects.<br></br>
            Concrete groove cutting machines are designed for a specific purpose: to create precise and well-defined grooves or channels in concrete surfaces<br></br>
            road cutting machine, on the other hand, is a specialized concrete cutting machine primarily used for road construction and maintenance. These machines are equipped with robust diamond blades or abrasive wheels capable of cutting through thick layers of asphalt or concrete on roads and highways. Road cutting machines are essential for various tasks
            .</p>

          <br></br>
        </div>
        <div className="products-container">
          <div className="search-bar">
            <input
              type="text"
              placeholder="Search products..."
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <button
              className="clear-button"
              onClick={() => setSearchInput('')}
            >
              Clear Search
            </button>
          </div>
          {filteredCompactors.map((product) => (
            <div className="product-card" key={product.name}>
              <div className="product-name">
                <h4>{product.name}</h4>
              </div>

              <div className="mid-grid">
                <img src={product.image} alt={product.name} className="product-image" />
                <table className="product-table">
                  <tbody>
                    {Object.entries(product).map(([key, value]) => (

                      key !== 'image' && key !== 'category' && (
                        <tr key={key}>
                          <td>{key}:</td>
                          <td>{value}</td>
                        </tr>
                      )
                    ))}
                  </tbody>
                </table>
              </div>


              <div className="end-grid">
                <div className="button-qo">
                  <button
                    className="quote-button"
                    onClick={() => {
                      setSelectedProduct(product);
                      setShowQuoteForm(true);
                    }}
                  >
                    Get Quote
                  </button>
                </div>


                {showQuoteForm && selectedProduct === product && (
                  <div className="overlay active">
                    <div className="quote-form active">
                      <form ref={quoteFormRef} onSubmit={handleSubmitQuote}>
                        <label>Name:</label>
                        <input
                          type="text"
                          className="quote-input"
                          value={quoteFormData.name}
                          placeholder='Name'
                          onChange={(e) => handleQuoteFormChange('name', e.target.value)}
                        />
                        <label>Contact:</label>
                        <input
                          type="tel"
                          className="quote-input"
                          value={quoteFormData.contact}
                          placeholder='Contact'
                          onChange={(e) => handleQuoteFormChange('contact', e.target.value)}
                        />

                        <label>Email:</label>
                        <input
                          type="email"
                          className="quote-input"
                          value={quoteFormData.email}
                          placeholder='Email'
                          onChange={(e) => handleQuoteFormChange('email', e.target.value)}
                        />

                        <label>Query:</label>
                        <textarea
                          className="quote-input"
                          value={quoteFormData.query}
                          placeholder='Your Query'
                          onChange={(e) => handleQuoteFormChange('query', e.target.value)}
                        ></textarea>
                        <button type="submit" className="submit-button">
                          Submit
                        </button>
                        <button
                          type="button"
                          className="close-button"
                          onClick={() => setShowQuoteForm(false)}
                        >
                          Close
                        </button>
                      </form>
                    </div>
                  </div>
                )}


                <div className="pdf">
                <button onClick={openPDF}>Products Brochure</button>
                </div>


                <div className="yt">
                  <a href="https://youtube.com/@orangeconstructionequipmen7482?si=JMeQdhEkfm5yV8Jd"><BsYoutube /></a>
                </div>

              </div>

            </div>
          ))}
        </div>

      </div>
      <Footer />
    </div>
  )
}

export default Groovecuter