import React from 'react';
import './Home.css';
import Navbar from './Navbar';
import About from './About';
import Productsection from './Productsection';
import Contact from './Contact';
import Footer from './Footer';
import { Helmet } from 'react-helmet';

const Home = () => {

  return (
    <div className="home-container">
      <Helmet>
        <title>Orange Construction Equipments | Construction Equipments</title>
        <meta name="description" content="Orange construction equipment refers to a category of heavy machinery  used in the construction industry." />
      </Helmet>
      <Navbar homeId="home" aboutId="about" productsId="products" contactId="contact" enquiryId="enquiry" />
      
      <section id="home" className="home-section">
        <div className="home-content">
          <h1 className="company-name">ORANGE CONSTRUCTION EQUIPMENTS <br></br> <span>&</span> <br></br><span className="able-blue">ABLE VIBRATORS</span></h1>
          <p className="slogan"><span className='slo'>Building the Future with Precision</span> <br></br>Your Trusted Steel and Cement Processing Partner</p>
          <a href="/contactform" className="cta-button">Contact Us</a>
        </div>
      </section>

      <section id="about" className="about-section">
        <About />
      </section>

      <section id="products" className="products-section">
        <Productsection />
      </section>

      <section id="contacts" className="contact-section">
        <Contact/>
      </section>

      <Footer/>
      
    </div>
  )
}

export default Home
