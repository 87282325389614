import React from 'react';
import './Contact.css'

function Contact() {
  return (
    <div>
      <h2>CONTACT US</h2>
      <div className="contact-us-page">
        

          <div className="contact-card">
            <h2>ORANGE CONSTRUCTION EQUIPMENTS</h2>
            <p>
              Gala no 9/11/12/13, Colaso Industrial Complex, Augustin 1 & 2, Waliv, Palghar- 401201 Maharashtra, India
            </p>
            <div className="contact-info">
              <p><span className="phone-icon">📞</span> <a href="tel:7262886771">7262886771</a></p>
              <p><span className="phone-icon">📞</span> <a href="tel:9921763603">9921763603</a></p>
              <p>Email: <a href="orangeconstructionequipments@gmail.com">orangeconstructionequipments@gmail.com</a></p>
            </div>

            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d486.1058408360359!2d77.494673!3d12.917571!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3f3fa361a7b1%3A0x7c2b891cce41dbca!2sOrange%20Construction%20Equipment!5e0!3m2!1sen!2sin!4v1693644597400!5m2!1sen!2sin" title="Location" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>

          <div className="contact-card">
            <h2>ABLE CONSTRUCTION EQUIPMENTS</h2>
            <p>
              Nardoli Wadi, Near Giriz Lake, Nardoli Gaon, Vasai West, Dist: Palghar-401201 Maharashtra India
            </p>
            <div className="contact-info">
              <p><span className="phone-icon">📞</span> <a href="tel:9820009469">9820009469</a></p>
              <p><span className="phone-icon">📞</span> <a href="tel:8600480004">9967054020</a></p>
              <p>Email:<a href="info@ablevibrators.com"> info@ablevibrators.com</a></p>
            </div>

            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7478.890107278879!2d72.7818708662345!3d19.376782823697418!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7ac163b54f1df%3A0x853e428c5df37b10!2sABLE%20VIBRATORS!5e0!3m2!1sen!2sin!4v1692199426760!5m2!1sen!2sin" title="Location" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>

        </div>
      
    </div>
  )
}

export default Contact