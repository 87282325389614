import './App.css';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import Home from './components/Home';
import Compactors from './productsPages/Compactors';
import Tampingrammers from './productsPages/Tampingrammers';
import Rollers from './productsPages/Rollers';
import Beemscreed from './productsPages/Beemscreed';
import Bullfloat from './productsPages/Bullfloat';
import Powertrowel from './productsPages/Powertrowel';
import Rideontrowel from './productsPages/Rideontrowel';
import Minitrowel from './productsPages/Minitrowel';
import Concretepaver from './productsPages/Concretepaver';
import Thrussscreed from './productsPages/Thrussscreed';
import Texturebrush from './productsPages/Texturebrush';
import Products from './components/Products'
import Suspendedplatform from './productsPages/Suspendedplatform';
import Minihoist from './productsPages/Minihoist';
import Concretemixer from './productsPages/Concretemixer';
import Groutpump from './productsPages/Groutpump';
import Moulds from './productsPages/Moulds';
import Testsieves from './productsPages/Testsieves';
import Slumcone from './productsPages/Slumcone';
import Ctm from './productsPages/Ctm';
import Plasterspray from './productsPages/Plasterspray';
import Tmt from './productsPages/Tmt';
import Pavercutter from './productsPages/Pavercutter';
import Platformvibrator from './productsPages/Platformvibrator';
import Toughrider from './productsPages/Toughrider';
import Immersiontype from './productsPages/Immersiontype';
import Portablevibrators from './productsPages/Portablevibrators';
import Highfrequencytype from './productsPages/Highfrequencytype';
import Dewateringpumps from './productsPages/Dewateringpumps';
import Externalshuttervibrator from './productsPages/Externalshuttervibrator';
import Barbending from './productsPages/Barbending';
import Barcutting from './productsPages/Barcutting';
import Spiralbending from './productsPages/Spiralbending';
import Cnc from './productsPages/Cnc';
import Ringmaking from './productsPages/Ringmaking';
import Decoiling from './productsPages/Decoiling';
import Scrapstraightening from './productsPages/Scrapstraightening';
import Threading from './productsPages/Threading';
import PopupForm from './components/PopupForm';
import Groovecuter from './productsPages/Groovecuter';
import Offer from './components/Offer';
import ScrollToTop from './components/ScrollToTop';
import Form from './components/Form';

//ErrorDocument 404 /index.html


function App() {
  return (
    <BrowserRouter>
    <PopupForm/>
    <ScrollToTop/>
          <Routes>
            <Route path="/" index element={<Home />} />
            <Route exact path="/products" index element={<Products/>} />
            <Route path="/compactors" element={<Compactors/> } />
            <Route path="/tampingrammers" element={<Tampingrammers/> } />
            <Route path="/rollers" element={<Rollers/> } />
            
            <Route path="/powertrowel" element={<Powertrowel/> } />
            <Route path="/rideontrowel" element={<Rideontrowel/> } />
            <Route path="/minitrowel" element={<Minitrowel/> } />
            <Route path="/bullfloat" element={<Bullfloat/> } />
            <Route path="/concretepaver" element={<Concretepaver/> } />
            <Route path="/beamscreed" element={<Beemscreed/> } />
            <Route path="/thrussscreed" element={<Thrussscreed/> } />
            <Route path="/texturebrush" element={<Texturebrush/> } />
            
            <Route path="/suspendedplatform" element={<Suspendedplatform/>} />
            <Route path="/minihoist" element={<Minihoist/>} />
            
            <Route path="/concretemixers" element={<Concretemixer/>} />
            
            <Route path="/groutpump" element={<Groutpump/>} />
            
            <Route path="/moulds" element={<Moulds/>} />
            <Route path="/testsieves" element={<Testsieves/>} />
            <Route path="/slumcone" element={<Slumcone/>} />
            <Route path="/cubetestingmachine" element={<Ctm/>} />
            <Route path="/plastersprayer" element={<Plasterspray/>} />
            <Route path="/tmt" element={<Tmt/>} />
            <Route path="/pavercutter" element={<Pavercutter/>} />
            <Route path="/platformvibrator" element={<Platformvibrator/>} />
            <Route path="/toughrider" element={<Toughrider/>} />
            
            <Route path="/immersiontype" element={<Immersiontype/>} />
            <Route path="/portablevibrators" element={<Portablevibrators/>} />
            <Route path="/externalshuttervibrator" element={<Externalshuttervibrator/>} />
            <Route path="/highfrequencytype" element={<Highfrequencytype/>} />
            <Route path="/dewateringpumps" element={<Dewateringpumps/>} />
            
            <Route path="/barbending" element={<Barbending/>} />
            <Route path="/barcutting" element={<Barcutting/>} />
            <Route path="/spiralbending" element={<Spiralbending/>} />
            <Route path="/cnc" element={<Cnc/>} />
            <Route path="/ringmaking" element={<Ringmaking/>} />
            <Route path="/decoiling" element={<Decoiling/>} />
            <Route path="/scrapstraightening" element={<Scrapstraightening/>} />
            <Route path="/threading" element={<Threading/>} />

            <Route path="/groovecutter" element={<Groovecuter/>} />

            <Route path="/about" element={<Offer/>} />

            <Route path="/contactform" element={<Form/>} />
          </Routes>
    </BrowserRouter>
  );
}

export default App;
