import React from 'react';
import './Footer.css'; // Import your custom CSS for styling
import { BsFacebook, BsLinkedin, BsInstagram, BsYoutube } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';


const Footer = () => {

  const navigate = useNavigate();


  return (
    <footer className="footer-container">
      <div className="footer-section">
        <h3>About Us</h3>
        <ul>
          <li onClick={() => navigate('/about')}>Our Offer</li>
          <li onClick={() => navigate('/about')}>About Us</li>
          <li onClick={() => navigate('/about')}>Why Choose Us?</li>
          <li onClick={() => navigate('/about')}>Sustainability</li>
          <li onClick={() => navigate('/about')}>Innovation</li>
          <ul>
            <li onClick={() => navigate('/products')}>Products Archive</li>
          </ul>
        </ul>
      </div>
      <div className="footer-section">
        <h3>Contact Us</h3>
        <ul>
          <li>
            <a href="https://www.google.com/maps/place/Orange+Construction+Equipment/@12.917511,77.495147,18z/data=!4m6!3m5!1s0x3bae3f3fa361a7b1:0x7c2b891cce41dbca!8m2!3d12.9175112!4d77.4951473!16s%2Fg%2F11kq433x6k?hl=en&entry=ttu">
              <h4>ORANGE CONSTRUCTION EQUIPMENTS</h4>
              <p>
                Waliv, Palghar- 401201 Maharashtra, India : 7262886771/9921763603
              </p>
            </a>
          </li>

          <li>
            <a href="https://www.google.com/maps?ll=19.375554,72.785272&z=14&t=m&hl=en&gl=IN&mapclient=embed&cid=9601184626239568656">
              <h4>ABLE CONSTRUCTION EQUIPMENTS</h4>
              <p>
                Vasai West, Dist: Palghar-401201 Maharashtra India : 9820009469/8600480004
                Email: info@ablevibrators.com
              </p>
            </a>
          </li>
        </ul>

      </div>
      <div className="footer-section">
        <h3>Social Media</h3>
        <ul className="social-icons">
          <li><a href="https://www.facebook.com/orangeconstructionequipments/"><BsFacebook /><span>Facebook</span></a></li>
          {/* <li><BsTwitter /><span>Twitter</span></li> */}
          <li><a href="https://www.linkedin.com/in/orange-construction-equipments-157b54281/"><BsLinkedin /><span>LinkedIn</span></a></li>
          <li><a href="https://www.instagram.com/orangeconstructionequipments/"><BsInstagram /><span>Instagram</span></a></li>
          <li><a href="https://www.youtube.com/channel/UCMoQD9yNUomnqCQa60GKlBw"><BsYoutube /><span>YouTube</span></a></li>
        </ul>
      </div>
      <div className="footer-section">
        <h3>FIND US</h3>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7478.890107278879!2d72.7818708662345!3d19.376782823697418!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7ac163b54f1df%3A0x853e428c5df37b10!2sABLE%20VIBRATORS!5e0!3m2!1sen!2sin!4v1692199426760!5m2!1sen!2sin" title="Location" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

      </div>

      <div className="copy-footer">
        <p>COPYRIGHT © 2019 ALL RIGHTS RESERVED</p>
      </div>

    </footer>
  );
};

export default Footer;
