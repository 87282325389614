import React from 'react';
import './About.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the carousel styles
import { Carousel } from 'react-responsive-carousel';

function About() {
  return (
    <div>
      <h2 className="us-about">ABOUT US</h2>
    <div className="about-us-container">
      <div className="carousel-container">
        <Carousel showArrows={true} showThumbs={false} infiniteLoop={true}
          autoPlay={true}
          interval={8000}>
          <div><img src="able1.jpg" alt="able" /></div>
          <div><img src="able2.jpg" alt="orange" /></div>
          <div><img src="able3.jpg" alt="orange" /></div>
        </Carousel>
      </div>
      <div className="info-container">
        <h2>Welcome to Able Vibrators</h2>
        <p>
          Able group of companies is a professional manufacturer producing world-class steel and cement processing products for building industry and concrete road industry. Located in India’s Commercial capital Mumbai, Our Machinery has 32 years history of research and development and has been involved in a wide variety of construction projects at home and abroad. Able range of steel and concrete processing machines has been widely used in domestic and international market. As a professional construction machine manufacturer in India, we will provide you reliable machines and best service throughout India
        </p>
        <h2>About Company</h2>
        <p>
          The name “Able Vibrators” was founded in 1985 on The basis of quality oriented business philosophy. The company was specialised in production of light construction machinery. With the excellent production equipment’s strict quality control developing new products and designs to meet customers demand. Its registered trade mark “ABLE” is regarded as a symbol of high quality and is on top leading manufacturer , also this is an ISO 9001;2008 certified company
        </p>
        <h2>Quality Control Technology & Research</h2>
        <p>
          With an industry experience of more than 20 years and adoption of advanced modern technology, our factory has produced high quality products, including bar cutting machine, steel bending machine, plate compactor, power trowel, tamping rammer, road cutter, concrete vibrate. Our industry-leading R&D team is continuously testing products with new approaches and advanced import instruments.
        </p>
      </div>
    </div>
    <div className="clients">
      <h3>OUR TOP CLIENTS</h3>
      <img src="clients.png" alt="clients" />
    </div>
    </div>
  )
}

export default About