import React from 'react';
import './Offer.css'
import Navbar from './Navbar';
import Footer from './Footer';
import Breadcrumb from './Breadcrumb';

function Offer() {

    const productsBreadcrumb = [
        { label: 'Home', url: '/' },
        { label: 'About Us', url: '/about' },
        // { label: 'Compactor', url: '/compactors' },
    ];

    return (
        <div>
            <Navbar />
            <div>
                <Breadcrumb items={productsBreadcrumb} />
            </div>
            <div className="offer-info-containerr">
                <div className="offer-sectionn">
                    <h2>Our Offer</h2>
                    <div className="offer-image">
                        <img src="offer.png" alt="offer" />
                    </div>
                    <p>
                        Able Vibrators offers a comprehensive range of soil and rock testing as well as compaction machines. These machines are designed and manufactured to meet the highest quality standards in the construction industry. Our product lineup includes soil compactors, tamping rammers, earth-moving machinery, and more. With a dedicated team of engineers and technicians, we constantly innovate and develop new products to cater to the evolving market needs. Each machine undergoes rigorous testing to ensure it meets the stringent "ABLE" quality standards before being released to the market.
                    </p>
                </div>

                <div className="about-sectionn">
                    <h2>About Us</h2>
                    <div className="offer-image">
                        <img src="about.png" alt="offer" />
                    </div>
                    <p>
                        Able Group of Companies, headquartered in Mumbai, India, is a leading manufacturer of top-notch steel and cement processing products for the building and concrete road industries. With a history spanning 32 years, we have been at the forefront of research and development in the construction machinery sector. Our expertise has been put to use in a diverse range of construction projects both locally and internationally. Our wide range of steel and concrete processing machines has gained recognition and acceptance in both domestic and global markets. As a dedicated construction machine manufacturer in India, we take pride in delivering reliable machinery and exceptional service across the country.
                    </p>
                </div>

                <div className="choose-us-sectionn">
                    <h2>Why Choose Us</h2>
                    <div className="offer-image">
                        <img src="whychooseus.png" alt="offer" />
                    </div>
                    <ul>
                        <li>Quality-Oriented Philosophy: Our foundation was built upon a commitment to quality. Since our establishment in 1985, the "ABLE" brand has become synonymous with superior quality in the construction machinery industry.</li>
                        <li>Experience and Expertise: With over three decades of industry experience, we possess a deep understanding of the needs and demands of the construction sector.</li>
                        <li>Advanced Technology: We integrate advanced modern technology into our manufacturing process, ensuring that our products are at the cutting edge of innovation.</li>
                        <li>Diverse Product Range: From soil compactors to tamping rammers, our diverse product range caters to various construction requirements.</li>
                        <li>Innovative Research: Our industry-leading R&D team consistently pushes boundaries, developing new approaches and products that meet emerging market trends.</li>
                        <li>Global Recognition: Our products have successfully met the scrutiny and testing standards of world-renowned manufacturers and dealers, establishing our reputation on a global scale.</li>
                    </ul>
                </div>

                <div className="sustainability-sectionn">
                    <h2>Sustainability</h2>
                    <div className="offer-image">
                        <img src="sustain.png" alt="offer" />
                    </div>
                    <p>
                        Sustainability is a growing concern in the construction industry. Able Vibrators can prioritize sustainable manufacturing processes, energy-efficient technologies, waste reduction, and environmentally friendly materials to contribute to a greener future.
                    </p>
                </div>

                <div className="innovation-sectionn">
                    <h2>Innovation</h2>
                    <div className="offer-image">
                        <img src="innovation.png" alt="offer" />
                    </div>
                    <p>
                        Innovation is a cornerstone of Able Vibrators' approach. The company continuously invests in research and development, allowing engineers and technicians to design new products that meet the evolving needs of the construction market. By integrating advanced technology and modern manufacturing techniques, Able Vibrators ensures that its products remain at the forefront of innovation, providing construction professionals with cutting-edge solutions to enhance their projects.
                    </p>
                </div>
            </div>
            <Footer />
        </div>

    )
}

export default Offer