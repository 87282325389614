import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Breadcrumb from './Breadcrumb';
import './Products.css'
import Navbar from './Navbar';
import Footer from './Footer';

const Products = () => {

    const navigate = useNavigate();
    const [selectedValue, setSelectedValue] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const handleDropdownChange = (event) => {
        const selectedOption = event.target.value;
        setSelectedValue(selectedOption);

        if (selectedOption) {
            navigate(`/${selectedOption}`);
        }
    };

    const productsBreadcrumb = [
        { label: 'Home', url: '/' },
        { label: 'Products', url: '/products' },
      ];

    return (
        <div className="section-content">
            <Navbar/>
            <div>
            <Breadcrumb items={productsBreadcrumb} />
            </div>
            
            <div className="feature-cards">
                <div className="feature-card">
                    <img src="soilcompaction.jpg" alt="Feature 1" className="category-image" />
                    <h4>Soil Compaction</h4>
                    <select value={selectedValue} onChange={handleDropdownChange} className='dropdown soil-compaction' >
                        <option value="">Products</option>
                        <option value="compactors">Compactors</option>
                        <option value="tampingrammers">Tamping Rammers</option>
                        <option value="rollers">Rollers</option>
                    </select>
                </div>

                <div className="feature-card">
                    <img src="surfaceleveling.jpg" alt="Feature 1" className="category-image" />
                    <h4>Surface Leveling</h4>
                    <select value={selectedValue} onChange={handleDropdownChange} className='dropdown'>
                        <option value="">Products</option>
                        <option value="powertrowel">Power Trowel</option>
                        <option value="rideontrowel">Ride on trowel</option>
                        <option value="minitrowel">Mini Trowel</option>
                        <option value="bullfloat">Bullfloat</option>
                        <option value="concretepaver"> Concrete Paver</option>
                        <option value="beamscreed">Single/Double Beam Screed</option>
                        <option value="thrussscreed">Truss Screed</option>
                        <option value="texturebrush">Texture Brush</option>
                    </select>
                </div>

                <div className="feature-card">
                    <img src="steelprocessing.jpg" alt="Feature 1" className="category-image" />
                    <h4>Steel Processing</h4>
                    <select value={selectedValue} onChange={handleDropdownChange} className='dropdown'>
                        <option value="">Products</option>
                        <option value="barbending">Bar Bending</option>
                        <option value="barcutting"> Bar Cutting</option>
                        <option value="spiralbending"> Spiral Bending</option>
                        <option value="cnc">CNC steel bar stirrup Bender</option>
                        <option value="ringmaking"> Ring Making Machine/Stirrup Bender</option>
                        <option value="decoiling">Decoiling machine</option>
                        <option value="scrapstraightening">Scrap Straightening machine</option>
                        <option value="threading"> TMT Threading Machine</option>
                    </select>
                </div>

                <div className="feature-card">
                    <img src="concretecutting.jpg" alt="Feature 1" className="category-image" />
                    <h4>Concrete Cutting</h4>
                    <select value={selectedValue} onChange={handleDropdownChange} className='dropdown'>
                        <option value="">Products</option>
                        <option value="groovecutter">Concrete Cutter/Groove Cutter</option>
                    </select>
                </div>

            
                <div className="feature-card">
                    <img src="suspendedplatform.jpg" alt="Feature 1" className="category-image" />
                    <h4>Suspended Platform</h4>
                    <select value={selectedValue} onChange={handleDropdownChange} className='dropdown'>
                        <option value="">Products</option>
                        <option value="suspendedplatform"> Suspended Platform</option>
                        <option value="minihoist"> Mini Lift Hoist</option>
                    </select>
                </div>

                <div className="feature-card">
                    <img src="concretemixer.jpg" alt="Feature 1" className="category-image" />
                    <h4>Concrete Mixers</h4>
                    <select value={selectedValue} onChange={handleDropdownChange} className='dropdown'>
                        <option value="">Products</option>
                        <option value="concretemixers"> Concrete Mixers</option>
                    </select>
                </div>

                <div className="feature-card">
                    <img src="groutpump.jpg" alt="Feature 1" className="category-image" />
                    <h4>Cement/Epoxy Grouting</h4>
                    <select value={selectedValue} onChange={handleDropdownChange} className='dropdown'>
                        <option value="">Products</option>
                        <option value="groutpump"> Grout pump</option>
                    </select>
                </div>

                <div className="feature-card">
                    <img src="labtest.jpg" alt="Feature 1" className="category-image" />
                    <h4>Lab Testing Equipments</h4>
                    <select value={selectedValue} onChange={handleDropdownChange} className='dropdown'>
                        <option value="">Products</option>
                        <option value="moulds"> Moulds</option>
                        <option value="testsieves"> Test Sieves</option>
                        <option value="slumcone"> Slum Cone Apparatus</option>
                        <option value="cubetestingmachine"> Cube Testing machine (CTM)</option>
                    </select>
                </div>
            
                <div className="feature-card">
                    <img src="concretevibrator.jpg" alt="Feature 1" className="category-image" />
                    <h4>Concrete Vibrator</h4>
                    <select value={selectedValue} onChange={handleDropdownChange} className='dropdown'>
                        <option value="">Products</option>
                        <option value="immersiontype"> Immersion Type</option>
                        <option value="portablevibrators"> Portable Vibrators</option>
                        <option value="highfrequencytype"> High Frequency Type</option>
                        <option value="externalshuttervibrator"> External/Shutter Vibrator</option>
                        <option value="dewateringpumps"> Dewatering Pumps</option>
                    </select>
                </div>

                <div className="feature-card">
                    <img src="paver.jpg" alt="Feature 1" className="category-image" />
                    <h4>Allied Construction Equipment</h4>
                    <select value={selectedValue} onChange={handleDropdownChange} className='dropdown'>
                        <option value="">Products</option>
                        <option value="plastersprayer">Plaster Sprayer</option>
                        <option value="tmt"> Rebar TMT Tying tool</option>
                        <option value="pavercutter"> Paver Cutter</option>
                        <option value="platformvibrator"> Platform Vibrator</option>
                        <option value="toughrider"> Tough Rider</option>
                    </select>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Products