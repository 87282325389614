import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import carousel styles
import { Carousel } from 'react-responsive-carousel';
import { useNavigate } from 'react-router-dom';
import './Productsection.css';


const Productsection = () => {

  const navigate = useNavigate();

  return (
    <div>
      <div className="pro">
      <h4>Cutting-Edge Products for Your Construction Needs</h4>
      <div className="products-container">
        <p className="product-description">
          With an industry experience of more than 20 years and adoption of advanced modern technology, our factory has produced high-quality products, including:
        </p>
        <div className="product-list">
          <ul>
            <li onClick={() => { navigate('/barbending') }}>Bar Bending</li>
            <li onClick={() => { navigate('/barcutting') }} >Bar Cutting</li>
            <li onClick={() => { navigate('/threading') }} >Threading Machines</li>
            <li onClick={() => { navigate('/tampingrammers') }} >Tamping Rammer</li>
            <li onClick={() => { navigate('/rollers') }} >Rollers</li>
            <li onClick={() => { navigate('/compactors') }} >Compactors</li>
            <li onClick={() => { navigate('/concretemixers') }} >Mixers</li>
            <li onClick={() => { navigate('/thrussscreed') }} >Truss Screed</li>
            <li onClick={() => { navigate('/groovecutter') }} >Groove Cutter</li>
          </ul>
        </div>
        
        </div>

      </div>
      <button className='explore-button' onClick={() => { navigate('/products') }}>Products Archive</button>
      
      <div className="carousel-container-a">

        <div className="slider">
          <h2>Featured Products</h2>

          <div className="carou-container">
            <Carousel className="product-slide" showArrows={true} showThumbs={false} infiniteLoop={true}
              autoPlay={true}
              interval={3000}>
              <div onClick={() => { navigate('/tampingrammers') }}>
                <img src="Tamping Rammer with Honda GX160 (Heavy Model).png" alt="able" />
                <h4>Tamping Rammers</h4>
              </div>

              <div onClick={() => { navigate('/barbending') }}>
                <img src="Blueline series GW 42J.png" alt="orange" />
                <h4>Bar Bending</h4>
              </div>

              <div onClick={() => { navigate('/barcutting') }}>
                <img src="Blueline series GQ 50 Blueline C-50.png" alt="able" />
                <h4>Bar Cutting</h4>
              </div>

              <div onClick={() => { navigate('/compactors') }}>
                <img src="ORANGE OR25 (3TON) COMPACTOR HONDA (GX160 PETROL)  WITH TANK.png" alt="orange" />
                <h4>Compactors</h4>
              </div>

              <div onClick={() => { navigate('/rollers') }}>
                <img src="Double Drum 850D (Ride on) with Greeves 10 HP Electric Start.png" alt="orange" />
                <h4>Rollers</h4>
              </div>

            </Carousel>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Productsection