import React, { useEffect, useState, useRef } from 'react';
import Breadcrumb from '../components/Breadcrumb';
import Navbar from '../components/Navbar';
import './Common.css'
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import { BsYoutube } from 'react-icons/bs';
import { Helmet } from 'react-helmet';
import emailjs from '@emailjs/browser';

const Powertrowel = () => {

  const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
  const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
  const userId = process.env.REACT_APP_EMAILJS_USER_ID;


  const [searchInput, setSearchInput] = useState('');

  const navigate = useNavigate();

  const productsBreadcrumb = [
    { label: 'Home', url: '/' },
    { label: 'Products', url: '/products' },
    { label: 'Power Trowel', url: '/powertrowel' },
  ];

  const openPDF = () => {
    window.open('/pdfs/Product Brochure.pdf', '_blank');
  };


  const [compactors, setCompactors] = useState([]);
  const [showQuoteForm, setShowQuoteForm] = useState(false);
  const [quoteFormData, setQuoteFormData] = useState({
    name: '',
    contact: '',
    email: '',
    query: '',
  });
  const [selectedProduct, setSelectedProduct] = useState(null);

  const quoteFormRef = useRef(); // Ref for the quote form

  useEffect(() => {
    // Fetch data from the local JSON file
    fetch('/data.json')
      .then((response) => response.json())
      .then((data) => {
        // Assuming "SoilCompaction" is the main array in your JSON file
        const compactorsData = data['SurfaceLeveling'];

        // Filter out the products with category "Compactors"
        const compactorsProducts = compactorsData.filter(
          (product) => product.category === 'Power Trowel'
        );

        setCompactors(compactorsProducts);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);


  //QuoteForm

  const handleQuoteFormChange = (field, value) => {
    setQuoteFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  // Function to send a quote request email using EmailJS
  const sendQuoteEmail = async () => {
    if (selectedProduct) {
      try {
        const response = await emailjs.send(
          serviceId,
          templateId,
          {
            product_name: selectedProduct.name, // Pass the product name to the template
            // Include other template variables here
            name: quoteFormData.name,
            contact: quoteFormData.contact,
            email: quoteFormData.email,
            query: quoteFormData.query,
          },
          userId
        );

        if (response.status === 200) {
          // Success, close the form and reset form data
          alert('Quote request submitted successfully!');
          setShowQuoteForm(false);
          setQuoteFormData({
            name: '',
            contact: '',
            email: '',
            query: '',
          });
        } else {
          console.error('Error submitting quote');
        }
      } catch (error) {
        console.error('Error submitting quote:', error);
        alert('Failed to send quote request. Please try again.');
      }
    }
  };

  const handleSubmitQuote = async (e) => {
    e.preventDefault();
    sendQuoteEmail();
  };


  const filteredCompactors = compactors.filter(product =>
    product.name.toLowerCase().includes(searchInput.toLowerCase())
  );


  return (
    <div>
      <Helmet>
        <title>Power Trowel | Power Floater | Power Trowel Machine</title>
        <meta name="description" content="Power Trowel and Power Floaters for Precision Finishing | High-Quality Power Trowel Machines for Smooth Concrete Surfaces | Enhance Construction Efficiency | Durable and Reliable Equipment for Your Concrete Projects - Your Trusted Power Trowel Source!" />
        <link rel="canonical" href="http://ablevibrators.com/#/powertrowel"></link>
      </Helmet>
      <Navbar />
      <div>
        <Breadcrumb items={productsBreadcrumb} />
      </div>
      <div className="compactors-page">
        <div className="all-about-products">
          <h1 className="page-title">Power Trowel</h1>
          <p className="product-desc">Power Trowel is a mechanical construction tool used to finish and smooth large concrete surfaces, such as floors, slabs, and pavements. It is designed to achieve a smooth, level, and polished finish on freshly poured concrete by mechanically troweling and polishing the surface. Power trowels are especially useful for achieving a consistent and professional appearance on concrete surfaces of significant size.</p>
          <p className="product-desc">Operation:
            The power trowel is guided by an operator who walks behind the machine as it moves over the freshly poured concrete surface.
            As the blades or pans rotate, they come into contact with the concrete, smoothing and leveling it out.
            The rotation of the blades or pans also contributes to the process of finishing and densifying the concrete, helping to eliminate imperfections, air pockets, and excess moisture.</p>
          <br></br>
          <p className="product-type">
            Other Surface Leveling categories :
          </p>
          <div className="product-list">
            <ul>
              <li onClick={() => { navigate('/rideontrowel') }} >Ride on trowel</li>
              <li onClick={() => { navigate('/minitrowel') }} >Mini Trowel</li>
              <li onClick={() => { navigate('/bullfloat') }} >Bullfloat</li>
              <li onClick={() => { navigate('/concretepaver') }} >Concrete Paver</li>
              <li onClick={() => { navigate('/beamscreed') }} >Single/Double Beam Screed</li>
              <li onClick={() => { navigate('/thrussscreed') }} >Truss Screed</li>
              <li onClick={() => { navigate('/texturebrush') }} >Texture Brush</li>
            </ul>
          </div>
        </div>
        <div className="products-container">
          <div className="search-bar">
            <input
              type="text"
              placeholder="Search products..."
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <button
              className="clear-button"
              onClick={() => setSearchInput('')}
            >
              Clear Search
            </button>
          </div>


          {filteredCompactors.map((product) => (
            <div className="product-card" key={product.name}>
              <div className="product-name">
                <h4>{product.name}</h4>
              </div>

              <div className="mid-grid">
                <img src={product.image} alt={product.name} className="product-image" />
                <table className="product-table">
                  <tbody>
                    {Object.entries(product).map(([key, value]) => (

                      key !== 'image' && key !== 'category' && (
                        <tr key={key}>
                          <td>{key}:</td>
                          <td>{value}</td>
                        </tr>
                      )
                    ))}
                  </tbody>
                </table>
              </div>


              <div className="end-grid">
                <div className="button-qo">
                  <button
                    className="quote-button"
                    onClick={() => {
                      setSelectedProduct(product);
                      setShowQuoteForm(true);
                    }}
                  >
                    Get Quote
                  </button>
                </div>


                {showQuoteForm && selectedProduct === product && (
                  <div className="overlay active">
                    <div className="quote-form active">
                      <form ref={quoteFormRef} onSubmit={handleSubmitQuote}>
                        <label>Name:</label>
                        <input
                          type="text"
                          className="quote-input"
                          value={quoteFormData.name}
                          onChange={(e) => handleQuoteFormChange('name', e.target.value)}
                        />
                        <label>Contact:</label>
                        <input
                          type="text"
                          className="quote-input"
                          value={quoteFormData.contact}
                          onChange={(e) => handleQuoteFormChange('contact', e.target.value)}
                        />

                        <label>Email:</label>
                        <input
                          type="email"
                          className="quote-input"
                          value={quoteFormData.email}
                          onChange={(e) => handleQuoteFormChange('email', e.target.value)}
                        />

                        <label>Query:</label>
                        <textarea
                          className="quote-input"
                          value={quoteFormData.query}
                          onChange={(e) => handleQuoteFormChange('query', e.target.value)}
                        ></textarea>
                        <button type="submit" className="submit-button">
                          Submit
                        </button>
                        <button
                          type="button"
                          className="close-button"
                          onClick={() => setShowQuoteForm(false)}
                        >
                          Close
                        </button>
                      </form>
                    </div>
                  </div>
                )}


                <div className="pdf">
                <button onClick={openPDF}>Products Brochure</button>
                </div>


                <div className="yt">
                  <a href="https://youtube.com/@orangeconstructionequipmen7482?si=JMeQdhEkfm5yV8Jd"><BsYoutube /></a>
                </div>

              </div>

            </div>
          ))}

        </div>

      </div>
      <Footer />
    </div>
  )
}

export default Powertrowel