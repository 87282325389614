import React, { useEffect, useState, useRef } from 'react';
import Breadcrumb from '../components/Breadcrumb';
import Navbar from '../components/Navbar';
import './Common.css'
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import { BsYoutube } from 'react-icons/bs';
import { Helmet } from 'react-helmet';
import emailjs from '@emailjs/browser';

const Suspendedplatform = () => {

  const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
  const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
  const userId = process.env.REACT_APP_EMAILJS_USER_ID;


  const [searchInput, setSearchInput] = useState('');

  const navigate = useNavigate();

    const productsBreadcrumb = [
        { label: 'Home', url: '/' },
        { label: 'Products', url: '/products' },
        { label: 'Suspended Platform', url: '/suspendedplatform' },
      ];

      const openPDF = () => {
        window.open('/pdfs/Product Brochure.pdf', '_blank');
      };
    
    
      const [compactors, setCompactors] = useState([]);
      const [showQuoteForm, setShowQuoteForm] = useState(false);
      const [quoteFormData, setQuoteFormData] = useState({
        name: '',
        contact: '',
        email: '',
        query: '',
      });
      const [selectedProduct, setSelectedProduct] = useState(null);

      const quoteFormRef = useRef(); // Ref for the quote form
    
      useEffect(() => {
        // Fetch data from the local JSON file
        fetch('/data.json')
          .then((response) => response.json())
          .then((data) => {
            // Assuming "SoilCompaction" is the main array in your JSON file
            const compactorsData = data['SuspendedPlatform'];
    
            // Filter out the products with category "Compactors"
            const compactorsProducts = compactorsData.filter(
              (product) => product.category === 'Suspended Platform'
            );
    
            setCompactors(compactorsProducts);
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      }, []);


      //QuoteForm
    
      const handleQuoteFormChange = (field, value) => {
        setQuoteFormData((prevData) => ({
          ...prevData,
          [field]: value,
        }));
      };
    
      // Function to send a quote request email using EmailJS
  const sendQuoteEmail = async () => {
    if (selectedProduct) {
      try {
        const response = await emailjs.send(
          serviceId,
          templateId,
          {
            product_name: selectedProduct.name, // Pass the product name to the template
            // Include other template variables here
            name: quoteFormData.name,
            contact: quoteFormData.contact,
            email: quoteFormData.email,
            query: quoteFormData.query,
          },
          userId
        );

        if (response.status === 200) {
          // Success, close the form and reset form data
          alert('Quote request submitted successfully!');
          setShowQuoteForm(false);
          setQuoteFormData({
            name: '',
            contact: '',
            email: '',
            query: '',
          });
        } else {
          console.error('Error submitting quote');
        }
      } catch (error) {
        console.error('Error submitting quote:', error);
        alert('Failed to send quote request. Please try again.');
      }
    }
  };

  const handleSubmitQuote = async (e) => {
    e.preventDefault();
    sendQuoteEmail();
  };
    
      const filteredCompactors = compactors.filter(product =>
        product.name.toLowerCase().includes(searchInput.toLowerCase())
      );

  return (
    <div>
      <Helmet>
        <title>Suspended Platform | Rope Suspended Platform</title>
        <meta name="description" content="Suspended Platform, also known as a swing stage or suspended scaffold, is a temporary elevated work platform used in construction, maintenance, and other industries." />
        <link rel="canonical" href="http://ablevibrators.com/#/suspendedplatform"></link>
      </Helmet>
      <Navbar />
      <div>
        <Breadcrumb items={productsBreadcrumb} />
      </div>
      <div className="compactors-page">
        <div className="all-about-products">
          <h1 className="page-title">Suspended Platform</h1>
          <p className="product-desc">Suspended platform, also commonly referred to as a rope suspended platform, is a versatile and essential piece of equipment in various industries that require work at significant heights. It plays a pivotal role in enabling workers to safely access and perform tasks on tall structures, including skyscrapers, bridges, facades, and industrial installations. These platforms are suspended from above, providing a stable and secure workspace for workers, while also allowing for mobility and ease of access to challenging locations.<br></br>

One of the most common variations of a suspended platform is the rope suspended platform. In this setup, the platform is suspended by robust steel ropes or cables from a secure anchoring point above. These ropes are engineered to withstand substantial weight loads, ensuring the safety of workers and equipment.<br></br>

The design of rope suspended platforms allows for vertical movement, enabling workers to ascend or descend as needed. This mobility is a significant advantage when working on structures with varying heights or intricate designs. Workers can precisely position themselves at the required height, making it possible to perform tasks with precision and efficiency.
</p>
          <br></br>
          <p className="product-type">
            Other Suspended Platform categories are :
          </p>
          <div className="product-list">
            <ul>
              <li onClick={() => { navigate('/minihoist') }} >Mini Lift Hoist</li>
            </ul>
          </div>
        </div>
        <div className="products-container">
        <div className="search-bar">
            <input
              type="text"
              placeholder="Search products..."
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <button
              className="clear-button"
              onClick={() => setSearchInput('')}
            >
              Clear Search
            </button>
          </div>
          {filteredCompactors.map((product) => (
            <div className="product-card" key={product.name}>
              <div className="product-name">
                <h4>{product.name}</h4>
              </div>

              <div className="mid-grid">
                <img src={product.image} alt={product.name} className="product-image" />
                <table className="product-table">
                  <tbody>
                    {Object.entries(product).map(([key, value]) => (

                      key !== 'image' && key !== 'category' && (
                        <tr key={key}>
                          <td>{key}:</td>
                          <td>{value}</td>
                        </tr>
                      )
                    ))}
                  </tbody>
                </table>
              </div>


              <div className="end-grid">
                <div className="button-qo">
                  <button
                    className="quote-button"
                    onClick={() => {
                      setSelectedProduct(product);
                      setShowQuoteForm(true);
                    }}
                  >
                    Get Quote
                  </button>
                </div>


                {showQuoteForm && selectedProduct === product && (
                  <div className="overlay active">
                    <div className="quote-form active">
                      <form ref={quoteFormRef} onSubmit={handleSubmitQuote}>
                        <label>Name:</label>
                        <input
                          type="text"
                          className="quote-input"
                          value={quoteFormData.name}
                          placeholder='Name'
                          onChange={(e) => handleQuoteFormChange('name', e.target.value)}
                        />
                        <label>Contact:</label>
                        <input
                          type="tel"
                          className="quote-input"
                          value={quoteFormData.contact}
                          placeholder='Contact'
                          onChange={(e) => handleQuoteFormChange('contact', e.target.value)}
                        />

                        <label>Email:</label>
                        <input
                          type="email"
                          className="quote-input"
                          value={quoteFormData.email}
                          placeholder='Email'
                          onChange={(e) => handleQuoteFormChange('email', e.target.value)}
                        />

                        <label>Query:</label>
                        <textarea
                          className="quote-input"
                          value={quoteFormData.query}
                          placeholder='Your Query'
                          onChange={(e) => handleQuoteFormChange('query', e.target.value)}
                        ></textarea>
                        <button type="submit" className="submit-button">
                          Submit
                        </button>
                        <button
                          type="button"
                          className="close-button"
                          onClick={() => setShowQuoteForm(false)}
                        >
                          Close
                        </button>
                      </form>
                    </div>
                  </div>
                )}


                <div className="pdf">
                <button onClick={openPDF}>Products Brochure</button>
                </div>


                <div className="yt">
                  <a href="https://youtube.com/@orangeconstructionequipmen7482?si=JMeQdhEkfm5yV8Jd"><BsYoutube /></a>
                </div>

              </div>

            </div>
          ))}
        </div>

      </div>
      <Footer />
    </div>
  )
}

export default Suspendedplatform