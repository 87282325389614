import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {BsTelephoneFill} from 'react-icons/bs'
import './Navbar.css'

function Navbar() {

  const [active, setActive] = useState("navbar-menu");

  const navToggle = () => {
    active === 'navbar-menu'
      ? setActive("navbar-menu nav_active")
      : setActive("navbar-menu")
  };

  const navigate = useNavigate();

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleButtonClick = (path, sectionId) => {
    navigate(path);
    if (sectionId) {
      setTimeout(() => {
        scrollToSection(sectionId);
      });
    }
  };

  return (
    <nav>
      <div className="navbar-container">
        <div className="logo">
          <img src="orange_logo.png" alt="Logo" onClick={() => navigate('/')} />
          <img src="able.png" alt="Logo" onClick={() => navigate('/')} />
        </div>
        <ul className={active}>
          <li>
            <button onClick={() => handleButtonClick('/', 'home')}>Home</button>
          </li>
          <li>
            <button onClick={() => handleButtonClick('/', 'about')}>About</button>
          </li>
          <li>
            <button onClick={() => handleButtonClick('/', 'products')}>Products</button>
          </li>
          <li>
            <button onClick={() => handleButtonClick('/', 'contacts')}>Contact</button>
          </li>

            <li  className="enq-link">
              <button><BsTelephoneFill></BsTelephoneFill><a href="tel:7262886771" className="tel">Call</a></button>
            </li>
        

          <li className="location">
            <a href="https://goo.gl/maps/VL4HvXBH3e3dSaSK7"><img src="google-maps.png" alt="Logo" /></a>
          </li>


        </ul>
        <div onClick={navToggle} className="toggler">
          <div className="line1"></div>
          <div className="line1"></div>
          <div className="line1"></div>
        </div>
      </div>

    </nav>
  )
}

export default Navbar