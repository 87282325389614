import React from 'react';
import './Breadcrumb.css'; // Make sure to create this CSS file

const Breadcrumb = ({ items }) => {
  return (
    <div className="breadcrumb">
      <ul>
        {items.map((item, index) => (
          <li key={index}>
            <a href={item.url}>{item.label}</a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Breadcrumb;
