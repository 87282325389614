import React, { useState, useEffect, useCallback, useRef } from 'react';
import './PopupForm.css'; // Import your CSS file for styling
import emailjs from '@emailjs/browser'; // Import EmailJS

const PopupForm = () => {
  const form = useRef();

  const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
  const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID_CONTACT;
  const userId = process.env.REACT_APP_EMAILJS_USER_ID;

  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    user_contact: '',
    message: '',
  });

  const [isFormOpen, setIsFormOpen] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsFormOpen(true);
    }, 8000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleCloseForm = useCallback(() => {
    setIsFormOpen(false);
  }, []);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();

    try {
      const response = await emailjs.sendForm(
        serviceId, // Replace with your EmailJS service ID
        templateId, // Replace with your EmailJS template ID
        form.current, // Pass the form element
        userId // Replace with your EmailJS user ID
      );

      console.log('EmailJS response:', response);

      if (response.status === 200) {
        alert('Request submitted successfully!');
        console.log('Email sent successfully');
      } else {
        console.log('Failed to send email');
      }

      // Reset the form after submission
      e.target.reset();
    } catch (error) {
      console.error('EmailJS error:', error);
      alert('Failed to send request. Please try again.');
    }

    setIsFormOpen(false);
  }, [serviceId, templateId, userId]);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  }, []);

  return (
    <>
      <div className={`popup ${isFormOpen ? 'active' : ''}`}>
        <div className="popup-content">
          <span className="close-icon" onClick={handleCloseForm}>
            &times;
          </span>
          <h2>Contact Us</h2>
          <form ref={form} onSubmit={handleSubmit}>
            <label htmlFor="user_name">Name:</label>
            <input
              type="text"
              id="user_name"
              name="user_name"
              value={formData.user_name}
              placeholder='Name'
              onChange={handleChange}
              required
            />

            <label htmlFor="user_email">Email:</label>
            <input
              type="email"
              id="user_email"
              name="user_email"
              value={formData.user_email}
              placeholder='Email'
              onChange={handleChange}
              required
            />

            <label htmlFor="user_contact">Contact:</label>
            <input
              type="tel"
              id="user_contact"
              name="user_contact"
              value={formData.user_contact}
              placeholder='Contact'
              onChange={handleChange}
              required
            />

            <label htmlFor="message">Enquiry:</label>
            <textarea
              id="message"
              name="message"
              rows="4"
              value={formData.message}
              placeholder='Your Query'
              onChange={handleChange}
              required
            ></textarea>

            <button type="submit" className="popupbut">
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default PopupForm;
